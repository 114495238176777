import React, { useEffect, useRef, useState } from "react";

// antd
import { Popconfirm } from "antd";

//react-router
import { connect, useSelector } from "react-redux";

import $, { data } from "jquery";
import { Link, useHistory } from "react-router-dom";

//action
import { getUserDetail } from "../store/user/action";
import {
  getPost,
  getComment,
  getLike,
  deleteComment,
} from "../store/post/action";
import { getFollowersFollowing } from "../store/follower/action";
import { getVideo } from "../store/video/action";
import { editCoin, updateUserInfo } from "../store/user/action";
import { getAgency } from "../store/agency/action";
//serverPath
import { baseURL } from "../util/Config";

import { permissionError } from "../util/Alert";

// dayjs
import dayjs from "dayjs";

//image
import Male from "../assets/images/male.png";
import noImage from "../assets/images/noImage.png";
//inline edit
import EdiText from "react-editext";

//toast
import { Toast } from "../util/Toast";
import { convertThousandNumbers } from "../util/Helper";

const UserDetail = (props) => {
  const history = useHistory();
  const usernameInputRef = useRef("");
  const userIdPre = props.match.params.userId;

  const post = useSelector((state) => state.post.post);
  const video = useSelector((state) => state.video.video);
  const followersFollowingList = useSelector(
    (state) => state.followersFollowing.followersFollowing
  );

  const comment = useSelector((state) => state.post.comment);
  const like = useSelector((state) => state.post.like);
  const [isEditingAgency, setIsEditingAgency] = useState(false);
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const agency = useSelector((state) => state.agency.agency);
  // eslint-disable-next-line
  const [showFeed, setShowFeed] = useState(true);
  const [followersFollowing, setFollowersFollowing] = useState(false);
  const [postVideo, setPostVideo] = useState([]);

  const [coin, setCoin] = useState();
  const [isCoin, setIsCoin] = useState(false);
  const [userAgency, setUserAgency] = useState(null);

  const [user, setUser] = useState([]);


  const [diamond, setDiamond] = useState();
  const [isDiamond, setIsDiamond] = useState(false);
  const [username, setUsername] = useState(user?.username);
  

  const [editUsername, setEditUsername] = useState(false);
  const [errors, setError] = useState({
    userAgency: "",
  });
  $(document).ready(() => {
    $("#manageVideoFeed").on("click", "a", function () {
      // remove className 'active' from all li who already has className 'active'
      $("#manageVideoFeed a.active").removeClass("active");
      // adding className 'active' to current click li
      $(this).addClass("active");
      if ($(this).attr("at") === "Videos") {
        setShowFeed(false);
        setFollowersFollowing(false);
      } else {
        if ($(this).attr("at") === "Feed") {
          setShowFeed(true);
          setFollowersFollowing(false);
        } else setFollowersFollowing(true);
      }
    });
  });

  useEffect(() => {
    getUserInfo(userIdPre);
    if(isFollowers){
      props.getFollowersFollowing("follower", userIdPre);
    } else if (isFollowing){
      props.getFollowersFollowing("following", userIdPre);
    }
    
  }, [userIdPre]);
  
  const getUserInfo = (userIdPre) => {
        getUserDetail(userIdPre)
          .then((res) => {
            setUser(res.data);
            setUserAgency(res.data.agencyId ?? null);
          })
          .catch((err) => {
              if (err.http_code == 404) {
                console.error("Error:", err);
              }
          });
  };

  document.addEventListener(
    "play",
    function (e) {
      var videos = document.getElementsByTagName("video");
      for (var i = 0, len = videos.length; i < len; i++) {
        // eslint-disable-next-line
        if (videos[i] != e.target) {
          videos[i].pause();
        }
      }
    },
    true
  );

  useEffect(() => {
    $("#manageVideoFeed li a").first().addClass("active");
  }, []);
  useEffect(() => {
    props.getAgency(1, 50, "")
  }, []);


  useEffect(() => {
    // props.getPost(user?._id); // eslint-disable-next-line
    props.getPost(userIdPre); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPostVideo(post);
  }, [post]);
  useEffect(() => {
    setPostVideo(video);
  }, [video]);

  let now = dayjs();

  const handleLike = (id, index, type) => {
    $(`#commentWrap${index}`).slideUp();
    $(`#likeWrap${index}`).slideToggle("slow");
    props.getLike(id, type);
  };
  const handleComment = (id, index, type) => {
    $(`#likeWrap${index}`).slideUp();
    $(`#commentWrap${index}`).slideToggle("slow");
    props.getComment(id, type);
  };
  const handleDeleteAgency = async () => {
    const shouldDelete = window.confirm("Are you sure you want to delete agency?");

    if (shouldDelete) {
      // 构建要发送到后端的数据对象
      const data = {
        userId: user._id,
        agencyId: null,
      };

      // 向后端发送请求，删除用户的机构
      const success = await props.updateUserInfo(data);

      if (success) {
        // 如果删除成功，将编辑状态设置为 false
        setIsEditingAgency(false);
        setUserAgency()
      } else {
        // 处理删除失败的情况，可以显示错误消息
        console.log("Agency deletion failed");
      }
    }
  };
  const handleNoIconClick = () => {
    setIsEditingAgency(false);
  };
  const handleYesIconClick = async () => {
    let data = {
      userId: user._id,
      agencyId: userAgency._id,
    };
    let success = await props.updateUserInfo(data)
    if (success) {
      // 如果更新成功，将编辑状态设置为 false
      setIsEditingAgency(false);
      getUserInfo(userIdPre);
    }
  };

  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowers, setIsFollowers] = useState(false);

  const handleFollowersFollowing = (type, id) => {
    if(type == "follower"){
      setIsFollowers(true);
      setIsFollowing(false);
    } else if (type == "following"){
      setIsFollowing(true);
      setIsFollowers(false);
    }
    props.getFollowersFollowing(type, id);
  };

  const handleVideo = (id) => {
    props.getVideo(id);
  };
  const handlePost = (id) => {
    props.getPost(id);
  };


  function handleDelete(commentId, index) {
    if (!hasPermission) return permissionError();
    const commentCount = $(`#comment${index}`).text();
    parseInt(commentCount) > 0 &&
      $(`#comment${index}`).text(parseInt(commentCount) - 1);
    props.deleteComment(commentId);
  }

  function getUserAgencyName(userAgency) {

    // if (userAgency?._id) {
    //   // 检查是否有有效的 userAgency
    //   return userAgency.name; // 返回机构名称
    // } else {
    //   return "Click Icon to set"; // 如果没有有效的 userAgency，返回默认值
    // }
    if (userAgency && agency && Array.isArray(agency)) {
      const existsAgency = agency.find(data => data._id === userAgency);
      if (existsAgency) {
        return existsAgency.name;
      }
    } else {
      return "Click Icon to set"; // 如果没有有效的 userAgency，返回默认值
    }

  }

  const handleUserInfo = (user) => {
    history.push("/admin/user/detail/"+ user._id);
  };

  const handleSave = (coin, id, type) => {
    if (!hasPermission) return permissionError();
    let coinNumber = coin.replace(/,/g, "");
    const validNumber = /^\d+$/.test(coinNumber);
    if (!validNumber) {
      return Toast("error", "Invalid Value");
    }
    let data;
    if (type === "rCoin") {
      setIsCoin(true);
      setCoin(coin);
      data = {
        userId: id,
        rCoin: coinNumber,
      };
    } else {
      setIsDiamond(true);
      setDiamond(coin);
      data = {
        userId: id,
        diamond: coinNumber,
      };
    }
    props.editCoin(data);
  };

  const handleUpdateUsername = async (val, id) => {
    if (!hasPermission) return permissionError();

    var reg = new RegExp(/^[A-Za-z0-9]*$/);
    if ((reg.test(val))) {
      let data = {
        userId: id,
        username: val,
      };
      let success = await props.updateUserInfo(data)
      if (!success) {
        setEditUsername(true)
        setUsername(user.username)
      } else setUsername(val)

    } else return Toast("error", "Username must contain only letters and numbers");
  }

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last"></div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/user" className="text-danger">
                    User
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="profile-cover"></div>
          <div class="profile-header ">
            <div class="profile-img">
              <img src={user.image ? user.image : Male} alt="" />
            </div>
            <div class="profile-name">
              <h3>{user?.name}</h3>
            </div>
            <div class="profile-header-menu">
              <ul class="list-unstyled" id="manageVideoFeed">
                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    at="Feed"
                    onClick={() => handlePost(user?._id)}
                  >
                    Feed ({user?.post})
                  </a>
                </li>

                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    at="Videos"
                    onClick={() => handleVideo(user?._id)}
                  >
                    Videos ({user?.video})
                  </a>
                </li>
                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    onClick={() =>
                      handleFollowersFollowing("follower", user?._id)
                    }
                  >
                    Followers ({user?.followers})
                  </a>
                </li>
                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    onClick={() =>
                      handleFollowersFollowing("following", user?._id)
                    }
                  >
                    Following ({user?.following})
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">About</h5>
              <span className="text-danger">{user?.bio ? "Bio : " : ""}</span>
              {user?.bio && (
                <span>{user?.bio}</span>
              )}
              {user?.bio && <br />}
              {user?.bio && <br />}
              
              <ul class="list-unstyled profile-about-list">
                <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i class="fal fa-user m-r-xxs"></i>
                    Username &nbsp;
                    {/* <span className="text-danger">{user?.username}</span> */}
                    <EdiText
                      type="text"
                      value={user?.username}
                      viewProps={{ className: "text-danger" }}
                      onSave={(val) => handleUpdateUsername(val, user?._id)}
                      className="editClass"
                      inputProps={{ ref: usernameInputRef }}
                      editing={editUsername}
                    />
                  </span>
                </li>
                <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i class="far fa-gem m-r-xxs"></i>
                    Diamond &nbsp;
                    <EdiText
                      type="text"
                      value={convertThousandNumbers( user?.diamond ? user.diamond : isDiamond ? diamond: 0 )}
                      onSave={(val) => handleSave(val, user?._id, "diamond")}
                      className="editClass"
                    />
                  </span>
                </li>
                <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i class="far fa-registered m-r-xxs "></i>
                    RCoin &nbsp;{" "}
                    <EdiText
                      type="text"
                      value={convertThousandNumbers(user?.rCoin ? user.rCoin : isCoin ? coin : 0)}
                      onSave={(val) => handleSave(val, user?._id, "rCoin")}
                      className="editClass"
                    />
                  </span>
                </li>
                <li>
                  <i class="fas fa-venus-mars m-r-xxs"></i>
                  <span>
                    Gender &nbsp;{" "}
                    <span className="text-danger">{user?.gender}</span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-child m-r-xxs"></i>
                  <span>
                    Age &nbsp;<span className="text-danger">{user?.age}</span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-phone m-r-xxs"></i>
                  <span>
                    Phone No &nbsp;<span className="text-danger">{user?.phoneNo ? user.phoneNo : "-"}</span>
                  </span>
                </li>

                <li>
                  <i class="fas fa-sign-in-alt m-r-xxs"></i>
                  <span>
                    Login Type &nbsp;
                    <span className="text-danger">
                      {user?.loginType === 0
                        ? "Google"
                        : user?.loginType === 1
                          ? "Facebook"
                          : "Quick"}
                    </span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-crown m-r-xxs"></i>
                  <span>
                    isVIP &nbsp;
                    <span
                      className={`${user?.isVIP ? "text-success" : "text-primary"
                        }`}
                    >
                      {user?.isVIP ? "Yes" : "No"}
                    </span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-user-tie m-r-xxs"></i>
                  <span>
                    Reseller &nbsp;
                    <span
                      className={`${user?.isAgent ? "text-success" : "text-primary"
                        }`}
                    >
                      {user?.isAgent ? "Yes" : "No"}
                    </span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-star m-r-xxs"></i>
                  <span>
                    Featured &nbsp;
                    <span
                      className={`${user?.isFeatured ? "text-success" : "text-primary"
                        }`}
                    >
                      {user?.isFeatured ? "Yes" : "No"}
                    </span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-headset m-r-xxs"></i>
                  <span>
                    Streamer &nbsp;
                    <span
                      className={`${user?.isStreamer ? "text-success" : "text-primary"
                        }`}
                    >
                      {user?.isStreamer ? "Yes" : "No"}
                    </span>
                  </span>
                </li>
                {user?.isStreamer && (
                  <li className="d-flex align-items-center">
                    <i class="fas fa-user m-r-xxs"></i>
                    <label className="text-gray m-r-xxs">Agency</label>
                    <div className="d-flex align-items-center m-r-xxs">
                      {isEditingAgency ? (

                        // If status of edit is true, shows selecting and confirm icons
                        <div className="d-flex align-items-center" >
                          <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            value={userAgency?._id}
                            style={{ width: "80%", height: "50%" }}
                            onChange={(e) => {
                              let id = e.target.value;
                              setUserAgency(agency.find(item => item._id === id));
                              if (e.target.value === "Select Agency") {
                                return setError({
                                  ...errors,
                                  userAgency: "Please select an Agency!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  userAgency: "",
                                });
                              }
                            }}
                          >

                            <option value="Select Agency" disabled selected>
                              Select Agency
                            </option>
                            {agency.map((userAgency) => {
                              return (
                                <option value={userAgency._id} key={userAgency._id}>
                                  {userAgency.name}
                                </option>
                              );
                            })}
                          </select>
                          &nbsp;&nbsp;
                          <i class="fas fa-check m-r-xxs" style={{ fontSize: "1.2em", cursor: "pointer", textShadow: "1px 1px 2px rgba(0,0,0,0.6)" }} onClick={() => handleYesIconClick()}></i>
                          &nbsp;
                          <i class="fas fa-times m-r-xxs" style={{ fontSize: "1.2em", cursor: "pointer", textShadow: "1px 1px 2px rgba(0,0,0,0.6)" }} onClick={() => handleNoIconClick()}></i>
                          &nbsp;&nbsp;
                          {userAgency?._id && (
                            <i
                              className="m-r-xxs far fa-user-slash m-r-xxs"
                              onClick={() => handleDeleteAgency()}
                            ></i>
                          )}
                        </div>
                      ) : (

                        //If status of edit is false, shows Agency name and pencil icon
                        <div className="d-flex align-items-center">
                          <span className="text-danger">{getUserAgencyName(userAgency)}&nbsp;&nbsp;

                          </span>
                          <i class="m-r-xxs far fa-edit m-r-xxs" onClick={() => setIsEditingAgency(true)}></i>
                        </div>
                      )}
                    </div>
                  </li>
                )}

                <li>
                  <i class="fas fa-tv m-r-xxs"></i>
                  <span>
                    Moderator &nbsp;
                    <span
                      className={`${user?.isModerator ? "text-success" : "text-primary"
                        }`}
                    >
                      {user?.isModerator ? "Yes" : "No"}
                    </span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-map-marker m-r-xxs"></i>
                  <span>
                    Ip &nbsp;<span className="text-danger">{user?.ip}</span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-clock m-r-xxs"></i>
                  <span>
                    Last login date &nbsp;
                    <span className="text-danger">{user?.lastLogin}</span>
                  </span>
                </li>
                {/* <li class="profile-about-list-buttons">
                  <button class="btn btn-block btn-primary m-t-md icon_margin">Follow</button>
                  <button class="btn btn-block btn-secondary m-t-md icon_margin">Message</button>
                </li> */}
              </ul>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Contact Info</h5>
              <ul class="list-unstyled profile-about-list">
                <li>
                  <i class="far fa-envelope m-r-xxs"></i>
                  <span>{user?.email}</span>
                </li>
                <li>
                  <i class="far fa-compass m-r-xxs"></i>
                  <span>
                    Lives in <span>{user?.country}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="card card-bg">
            <div class="card-body ">
              {followersFollowing ? (
                <div
                  class="container followDiv"
                  style={{ maxHeight: 548, overflow: "auto" }}
                >
                  <div class="row post-comments d-flex flex-wrap justify-content-between">
                    {followersFollowingList?.length > 0 ? (
                      followersFollowingList.map((data, index) => {
                        return (
                          <div
                            class="col-md-6"
                            style={{
                              paddingRight: "10px",
                              borderRight: `${followersFollowingList.length > 1 &&
                                index % 2 === 0
                                ? "1px solid #7d7d83"
                                : "transparent"
                                }`,
                            }}
                          >
                            <div class="post-comm post-padding">
                              <img
                                src={
                                  data?.fromUserId.image
                                  ? data?.fromUserId.image
                                  : data?.toUserId.image
                                    ? data?.toUserId.image
                                    : Male
                                }
                                class="comment-img commentImg"
                                alt=""
                              />
                              <div
                                class="comment-container pointer-cursor"
                                onClick={() => {
                                  handleUserInfo(
                                    // data?.toUserId
                                    //   ? data?.toUserId
                                    //   : data?.fromUserId
                                    isFollowers 
                                    ? data?.fromUserId
                                    : isFollowing
                                    ? data?.toUserId
                                    : null
                                  )
                                  
                                }}
                              >
                                <span class="comment-author">
                                    {data?.fromUserId?.name
                                    ? data?.fromUserId?.name
                                    : data?.toUserId?.name}
                                  <small class="comment-date">
                                    {now.diff(data?.createdAt, "minute") <=
                                      60 &&
                                      now.diff(data?.createdAt, "minute") >= 0
                                      ? now.diff(data?.createdAt, "minute") +
                                      " minutes ago"
                                      : now.diff(data?.createdAt, "hour") >= 24
                                        ? dayjs(data?.createdAt).format(
                                          "DD MMM, YYYY"
                                        )
                                        : now.diff(data?.createdAt, "hour") +
                                        " hour ago"}
                                  </small>
                                </span>
                                <span>
                                    {data?.fromUserId?.username
                                    ? data?.fromUserId?.username
                                    : data?.toUserId?.username}
                                  {/* {data?.toUserId?.username
                                    ? data?.toUserId?.username?.length >= 22
                                      ? data?.toUserId?.username?.substr(
                                          0,
                                          22
                                        ) + "..."
                                      : data?.toUserId?.username
                                    : data?.fromUserId?.username?.length >= 22
                                    ? data?.fromUserId?.username?.substr(
                                        0,
                                        22
                                      ) + "..."
                                    : data?.fromUserId?.username} */}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-center">Nothing to Show!!</p>
                    )}
                  </div>
                </div>
              ) : postVideo.length > 0 ? (
                postVideo
                  .slice(0)
                  .reverse()
                  .map((data, index) => {
                    return (
                      <div class="post">
                        <div class="post-header">
                          <img src={user.image ? user.image : Male} alt="" />
                          <div class="post-info">
                            <span class="post-author">{user?.name}</span>
                            <br />
                            <span class="post-date">
                              {now.diff(data?.date, "minute") <= 60 &&
                                now.diff(data?.date, "minute") >= 0
                                ? now.diff(data?.date, "minute") +
                                " minutes ago"
                                : now.diff(data?.date, "hour") >= 24
                                  ? dayjs(data?.date).format("DD MMM, YYYY")
                                  : now.diff(data?.date, "hour") + " hour ago"}
                            </span>
                          </div>
                        </div>
                        {data.isDelete ? (
                          <p className="text-center">
                            This Post is delete by Admin
                          </p>
                        ) : (
                          <>
                            <div class="post-body">
                              <p>{data?.caption}</p>
                              {data?.post ? (
                                <img
                                  src={
                                    data.post ? baseURL + data?.post : noImage
                                  }
                                  class="post-image"
                                  alt=""
                                />
                              ) : (
                                <video
                                  src={baseURL + data?.video}
                                  class="post-image m-auto py-3"
                                  controls
                                  style={{ maxHeight: 400, width: "40%" }}
                                />
                              )}
                            </div>
                            <div id="myGroup">
                              <div class="post-actions">
                                <ul class="list-unstyled">
                                  <li>
                                    <a
                                      href={() => false}
                                      class="like-btn"
                                      onClick={() =>
                                        handleLike(
                                          data?._id,
                                          index,
                                          data.post ? "post" : "video"
                                        )
                                      }
                                      id="likeToggle"
                                    >
                                      {data?.like} Likes
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href={() => false}
                                      class="like-btn"
                                      role="button"
                                      onClick={() =>
                                        handleComment(
                                          data?._id,
                                          index,
                                          data.post ? "post" : "video"
                                        )
                                      }
                                      id="commentToggle"
                                    >
                                      <span id={`comment${index}`}>
                                        {data?.comment}
                                      </span>{" "}
                                      &nbsp; Comments
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div class="post-comments">
                                <div
                                  class="collapse"
                                  id={`likeWrap${index}`}
                                  style={{
                                    maxHeight: 250,
                                    overflow: "auto",
                                    display: "none",
                                  }}
                                >
                                  <div class="row">
                                    <div class="col-12">
                                      {like?.length > 0 ? (
                                        like.map((like) => {
                                          return (
                                            <div class="post-comm post-padding">
                                              <img
                                                src={
                                                  like.image ? like.image : Male
                                                }
                                                class="comment-img"
                                                alt=""
                                                onClick={() =>
                                                  handleUserInfo(like.user)
                                                }
                                              />
                                              <div class="comment-container pointer-cursor">
                                                <span class="comment-author">
                                                  <span
                                                    onClick={() =>
                                                      handleUserInfo(like.user)
                                                    }
                                                  >
                                                    {like.name}
                                                  </span>
                                                  <small class="comment-date">
                                                    {like.time}
                                                  </small>
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p className="text-center">
                                          No data found
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="collapse"
                                  id={`commentWrap${index}`}
                                  style={{ maxHeight: 250, overflow: "auto" }}
                                >
                                  <div class="row">
                                    <div class="col-12">
                                      {comment?.length > 0 ? (
                                        comment.map((comment) => {
                                          return (
                                            <div class="post-comm post-padding">
                                              <img
                                                src={
                                                  comment.image
                                                    ? comment.image
                                                    : Male
                                                }
                                                class="comment-img commentImg"
                                                alt=""
                                                onClick={() =>{
                                                    handleUserInfo(comment.user);
                                                  }
                                                }
                                              />
                                              <div class="comment-container">
                                                <span class="comment-author pointer-cursor">
                                                  <span
                                                    onClick={() =>
                                                      handleUserInfo(
                                                        comment.user
                                                      )
                                                    }
                                                  >
                                                    {comment.name}
                                                  </span>
                                                  <small class="comment-date">
                                                    {comment.time}
                                                    <Popconfirm
                                                      title="Are you sure to delete this comment?"
                                                      onConfirm={() =>
                                                        handleDelete(
                                                          comment._id,
                                                          index
                                                        )
                                                      }
                                                      okText="Yes"
                                                      cancelText="No"
                                                      placement="top"
                                                    >
                                                      <i className="fas fa-trash-alt text-danger comment-delete pointer-cursor"></i>
                                                    </Popconfirm>
                                                  </small>
                                                </span>
                                                <span className="pointer-cursor">
                                                  {comment.comment}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p className="text-center">
                                          No data found
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })
              ) : (
                <p className="text-center">Nothing to show!!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getPost,
  getLike,
  getComment,
  getFollowersFollowing,
  getVideo,
  getAgency,
  deleteComment,
  editCoin,
  updateUserInfo
})(UserDetail);
