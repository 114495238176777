import {
  CLOSE_ANNOUNCEMENT_DIALOG,
  CREATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_ALL_ANNOUNCEMENT,
  OPEN_ANNOUNCEMENT_DIALOG,
  UPDATE_ANNOUNCEMENT,
} from "./types";

const initialState = {
  announcement: [],
  dialog: false,
  dialogData: null,
};

const announcementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.payload.announcement,
      };

    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcement: state.announcement.filter((item) => item._id !== action.payload),
      };
      case CREATE_ANNOUNCEMENT:
        const data = [...state.announcement];
        data.unshift(action.payload);
        return {
          ...state,
          announcement: data,
        };
      case UPDATE_ANNOUNCEMENT:
        return {
          ...state,
          announcement: state.announcement.map((item) => {
            if (item._id === action.payload.id) return action.payload.data;
            else return item;
          }),
        };
    case OPEN_ANNOUNCEMENT_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_ANNOUNCEMENT_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    default:
      return state;
  }
};

export default announcementReducer;
