import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  GET_AGENCY,
  CREATE_NEW_AGENCY,
  UPDATE_AGENCY,
  CLOSE_AGENCY_DIALOG,
  DELETE_AGENCY,
} from "./types";
export const createNewAgency = (data) => (dispatch) => {
    axios
      .post(`agency`, data)
      .then((res) => {
        if (res.data.status) {
          Toast("success", "Agency created successfully!");
          dispatch({ type: CLOSE_AGENCY_DIALOG });
          dispatch({ type: CREATE_NEW_AGENCY, payload: res.data.agency });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };
export const getAgency = (page,rowsPerPage,searchValue) => (dispatch) => {
  axios
    .get(`/agency?start=${page}&limit=${rowsPerPage}&search=${searchValue}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_AGENCY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
  };

  export const updateAgency = (agencyId, data) => (dispatch) => {
    axios
      .patch(`/agency/${agencyId}`, data)
      .then((res) => {
        if (res.data.status) {
          Toast("success", "Agency updated successfully!");
          dispatch({ type: CLOSE_AGENCY_DIALOG });
          dispatch({
            type: UPDATE_AGENCY,
            payload: { data: res.data.agency, id: agencyId },
          });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };
  export const deleteAgency = (agencyId) => (dispatch) => {
    axios
      .delete(`/agency/${agencyId}`)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: DELETE_AGENCY, payload: agencyId });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => console.log(error));
  };



