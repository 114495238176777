import React, { useEffect, useState } from "react";

//redux
import { useSelector, connect, useDispatch } from "react-redux";

//serverpath
import { baseURL } from "../util/Config";

//sweet alert
import { alert, warning, permissionError } from "../util/Alert";

//action
import { deleteGift } from "../store/gift/action";

// type
import { OPEN_GIFT_DIALOG } from "../store/gift/types";

//routing
import { useHistory } from "react-router-dom";

//image
import noImage from "../assets/images/noImage.png";
import animatedGiftIcon from "../assets/images/animatedGiftIcon.png";

const AllGift = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const handleDelete = (giftId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          if (!hasPermission) return permissionError();
          props.deleteGift(giftId);
          alert("Deleted!", `Gift has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_GIFT_DIALOG, payload: data });
  };

  return (
    <>
      {props.data?.gift?.length > 0 ? (
        props.data?.gift?.map((data, index) => {
          return (
            <div
              className="col-md-2"
              key={index}
              onClick={() => {
                localStorage.setItem("CategoryId", data._id);
                history.push("/admin/giftCategory/gift");
              }}
            >
              <div className="card contact-card card-bg">
                <div className="card-body p-1">
                  <div className="px-3 py-4 text-center align-items-center">
                    <div className="pb-2">
                      {(data.type === "application/json" || data.image.toLowerCase().endsWith(".lottie") || data.type === "image/gif") &&
                        <img
                          src={animatedGiftIcon}
                          alt={"badge-" + index}
                          style={{ objectFit: "contain", position: "absolute", top: 10, right: 10, width: 30, height: 30 }}
                        />}
                      {data.type === "application/json" || data.image.toLowerCase().endsWith(".lottie") ?
                        <div className="d-flex align-items-center justify-content-center">
                          {data.preview ?
                            <img src={baseURL + data.preview} alt={"preview-" + data.category + index} height={80} style={{ objectFit: "contain" }} /> :
                            <dotlottie-player src={baseURL + data.image} autoplay loop style={{
                              width: 80,
                              height: 80,
                            }} />}
                        </div>
                        :
                        <img
                          src={data.image ? baseURL + (data.preview ?? data.image) : noImage}
                          alt={"image-" + data.category + index}
                          height={80}
                          style={{ objectFit: "contain" }}
                        />
                      }
                    </div>
                    <div
                      style={{
                        padding: 0,
                        paddingLeft: 5,
                      }}
                    >
                      <div class="contact-card-info mt-2 mb-3 px-3">
                        <h6>Coin: {data.coin}</h6>
                      </div>
                      <div className="px-3">
                        <i
                          class="fas fa-edit text-white p-2 bg-primary rounded-circle"
                          style={{ marginRight: 10 }}
                          onClick={() => handleEdit(data)}
                        ></i>

                        {/* <i
                          class="fas fa-trash text-white p-2 bg-danger rounded-circle"
                          onClick={() => handleDelete(data._id)}
                        ></i> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div align="center">
          Nothing to show!!
        </div>
      )}
    </>
  );
};

export default connect(null, { deleteGift })(AllGift);
