import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_ANNOUNCEMENT_DIALOG } from "../../store/announcement/types";

//action
import { createNewAnnouncement, editAnnouncement } from "../../store/announcement/action";
import { baseURL } from "../../util/Config";
import { permissionError } from "../../util/Alert";
import moment from "moment";
import { formatDateTime } from "../../util/Helper";


const AnnouncementDialog = (props) => {
  const dispatch = useDispatch();
  const announcementType = ["Alert","Pop"]

  const { dialog: open, dialogData } = useSelector((state) => state.announcement);
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const [mongoId, setMongoId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState(announcementType[0]);
  const [isVIP, setIsVIP] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  const [errors, setError] = useState({
    image: "",
  });

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setTitle(dialogData.title);
      setType(dialogData.type);
      setMessage(dialogData.message);
      setIsVIP(dialogData.isVIP);
      setStartDate(dialogData.startDateTime);
      setEndDate(dialogData.endDateTime);
      setImagePath(baseURL + dialogData.image);
    }
  }, [dialogData]);

  useEffect(
    () => () => {
      setError({
        image: "",
      });
      setMongoId("");
      setTitle("");
      setMessage("");
      setIsVIP(false)
      setStartDate("");
      setEndDate("");
      setType(announcementType[0]);
      setImageData(null);
      setImagePath(null);
    },
    [open]
  );

  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const closePopup = (reason) => {
    if(reason.type == "click")
      dispatch({ type: CLOSE_ANNOUNCEMENT_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!mongoId) {
      if (!imageData || !imagePath) {
        return setError({ ...errors, image: "Announcement Image is Required!" });
      }
    } else {
      if (!imageData && !imagePath) {
        return setError({ ...errors, image: "Announcement Image is Required!" });
      }
    }

    if (!hasPermission) return permissionError();
    const formData = new FormData();

    formData.append("image", imageData);
    formData.append("title", title);
    formData.append("message", message);
    formData.append("type", type);
    formData.append("isVIP", isVIP);
    formData.append("startDateTime", formatDateTime(startDate));
    formData.append("endDateTime", formatDateTime(endDate));


    if (mongoId) {
      props.editAnnouncement(mongoId, formData);
    } else {
      props.createNewAnnouncement(formData);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title"><span className="text-danger font-weight-bold h4" > Announcement </span></DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Message</label>
                  <textarea
                    rows={4}
                    className="form-control"
                    required=""
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Announcement Type</label>
                  <select
                    class="form-select form-control"
                    aria-label="Default select example"
                    value={type}
                    onChange={(e) => {
                        setType(e.target.value);
                    }}
                >
                  {announcementType.map(item=>{
                      return(
                          <option key={item} value={item}>{item}</option>
                      )
                  })}
                </select>
                </div>
                <div className="form-group row mt-4">
                  <div className="input-container col-6">
                    <label className="mb-2 text-gray">Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        for
                        required=""
                        value={moment(startDate).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                      />
                  </div>
                  <div className="input-container col-6">
                    <label className="mb-2 text-gray">End Date</label>
                      <input
                        type="date"
                        className="form-control"
                        required=""
                       value={moment(endDate).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      />
                  </div>
                </div>
                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Announcement Image</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept="image/*"
                    required=""
                    onChange={HandleInputImage}
                  />
                  {errors.image && (
                    <div className="ml-2 mt-1">
                      {errors.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.image}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {imagePath && (
                    <>
                      <img
                        height="70px"
                        width="70px"
                        alt="app"
                        src={imagePath}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                          // border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          float: "left",
                          objectFit: "cover"
                        }}
                      />
                    </>
                  )}
                </div>
                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { createNewAnnouncement, editAnnouncement })(AnnouncementDialog);
