/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
//MUI
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import { CLOSE_LIVE_DETAIL_DIALOG } from "../../store/streamerLiveSummary/types";

const LiveSummaryDetailDialog = (props) => {
    const dispatch = useDispatch();


    const { dialog: open, dialogData } = useSelector((state) => state.streamerLiveSummary);

    const closePopup = (reason) => {
        if (reason.type == "click"){
            dispatch({ type: CLOSE_LIVE_DETAIL_DIALOG });
        }
    };

    return (
        <>
            <Dialog
                open={open && dialogData.status}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableEscapeKeyDown
                fullWidth
                // maxWidth="xs"
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4">Live Summary Detail</span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div className="text-white px-3">
                        Completed Target Day(s) :  {dialogData?.additionalInfo?.completeCount??0}/{dialogData?.additionalInfo?.streamerTaskSetting?.minuteTask?.minimumRequirement}
                    </div>
                    <div className="modal-body pt-1 px-1 pb-3" style={{ maxHeight: 500, overflow: 'auto' }}>
                        <table className="table table-striped">
                        <thead>
                            <th>Stream Date</th>
                            <th>Stream Time (minutes)</th>
                        </thead>
                        <tbody>
                            {dialogData?.data?.map(item=>{
                                return(
                                    <tr key={item.streamDate}>
                                        <td>{item.streamDate}</td>
                                        {/* <td>{parseInt(item.totalDuration ?? 0) > 0 ? parseInt(item.totalDuration ?? 0):"Less than 1 minutes"}</td> */}
                                        <td>{parseInt(item.totalDuration ?? 0) > 0 ? parseInt(item.totalDuration ?? 0):"Less than 1 minutes"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        </table>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default (LiveSummaryDetailDialog);
