import React from "react";

//redux
import { useDispatch, useSelector, connect } from "react-redux";

//action
import { acceptRedeem } from "../../../store/redeem/action";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_ACCEPT_DIALOG } from "../../../store/redeem/types";

//sweet alert
import { permissionError } from "../../../util/Alert";

const AcceptConfirmation = (props) => {
  const dispatch = useDispatch();

  const { dialogAccept: openAccept, dialogData } = useSelector((state) => state.redeem);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const handleAccept = () => {
    if (!hasPermission) return permissionError();
    props.acceptRedeem(dialogData._id, 'accept');
    closePopup();
  };

  const closePopup = (reason) => {
    if (reason.type == "click")
        dispatch({ type: CLOSE_ACCEPT_DIALOG });
  };

  return (
    <>
      <Dialog
        open={openAccept}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">
            Accept Confirmation
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
                <span className="text-white">Are you sure to accept the redeem?</span>
                <div className="mt-5">
                    <button
                        type="button"
                        className="btn btn-primary ml-2 btn-round float__right icon_margin"
                        onClick={handleAccept}
                    >
                        Accept
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                        onClick={closePopup}
                    >
                        Close
                    </button>
                </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { acceptRedeem })(
    AcceptConfirmation
);
