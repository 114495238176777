/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_GIFT_DIALOG } from "../../../store/gift/types";

//action
import { editGift } from "../../../store/gift/action";
import { getCategory } from "../../../store/giftCategory/action";

// server path
import { baseURL } from "../../../util/Config";

import { imageOverSize, maximumUploadSize, permissionError } from "../../../util/Alert";

const GiftDialog = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.gift);

  const GiftClick = localStorage.getItem("GiftClick");

  const [mongoId, setMongoId] = useState("");
  // const [coin, setCoin] = useState("");
  // const [category, setCategory] = useState("");
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [previewPath, setPreviewPath] = useState(null);
  const categoryDetail = JSON.parse(localStorage.getItem("Category"));

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    props.getCategory(); // eslint-disable-next-line
  }, []);

  const categories = useSelector((state) => state.giftCategory.giftCategory);

  const [errors, setError] = useState({
    image: "",
    // coin: "",
    // category: "",
  });

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      // setCoin(dialogData.coin);
      // setCategory(
      //   GiftClick === null ? dialogData.category._id : dialogData.category
      // );
      setImagePath(baseURL + dialogData.image);
      if (dialogData.preview) setPreviewPath(baseURL + dialogData.preview);
    }
  }, [dialogData, GiftClick]);

  useEffect(
    () => () => {
      setError({
        image: "",
        preview: "",
        // coin: "",
        // category: "",
      });
      // setCategory("");
      // setCoin("");
      setPreviewImg(null);
      setPreviewPath(null);
      setImageData(null);
      setImagePath(null);
    },
    [open]
  );

  const handleInputImage = (e, type) => {
    if (type === "image") {
      setImageData(null);
      setImagePath(null);

      if (e.target.files[0]) {
        let file = e.target.files[0];
        if (file.size > maximumUploadSize) {
          imageOverSize();
        } else {
          setError({ ...errors, image: "" });
          if (file.type.startsWith('image/') || file.preview?.toLowerCase().endsWith(".lottie")) {
            // Handle image files: add preview URL
            Object.assign(file, { preview: URL.createObjectURL(file) });

            setImageData(file);
          } else if (file.type === 'application/json') {
            // Handle JSON files (potential Lottie animations)
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const jsonData = JSON.parse(reader.result);

                // Check if the JSON has necessary Lottie properties
                if (jsonData.v && jsonData.fr && jsonData.assets && jsonData.layers) {
                  // If it's a valid Lottie animation file, add the animation data to the file object
                  Object.assign(file, { preview: URL.createObjectURL(file) });
                  setImageData(file);
                } else {
                  setError((prevErrors) => ({
                    ...prevErrors,
                    image: 'This is not a valid Lottie JSON file.',
                  }));
                  setImageData(null);
                }
              } catch (error) {
                setError((prevErrors) => ({
                  ...prevErrors,
                  image: 'Invalid JSON file format.',
                }));
                setImageData(null);
              }
            };
            reader.readAsText(file);
          } else {
            setError((prevErrors) => ({
              ...prevErrors,
              image: 'Unsupported file type.',
            }));
            setImageData(null);
          }
        }
      }
    } else {
      let file = e.target.files[0];
      setPreviewImg(file);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreviewPath(reader.result);
      });
      reader.readAsDataURL(file);
    }
  }

  const closePopup = (reason) => {
    if (reason.type == "click")
      dispatch({ type: CLOSE_GIFT_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!imageData && !imagePath) {
      const errors = {};

      // if (!coin) errors.coin = "Coin is Required!";

      if (!imageData && !imagePath) errors.image = "Please select an Image!";

      // if (GiftClick !== null && (category === "Select Category" || !category)) {
      //   errors.category = "Please select a Category!";
      // }
    }
    // const coinValid = isNumeric(coin);
    // if (!coinValid) {
    //   return setError({ ...errors, coin: "Invalid Coin!!" });
    // }
    const formData = new FormData();

    if (imageData) formData.append("image", imageData);
    if (previewImg) formData.append("preview", previewImg);
    // formData.append("coin", coin);
    // GiftClick !== null && formData.append("category", category);

    if (!hasPermission) return permissionError();
    if (formData.get("image") || formData.get("preview")){
      props.editGift(formData, mongoId);
    }else{
      closePopup({ type: "click" });
    }
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  return dialogData && (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Gift </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form className="row g-3">
                <div className="form-group col-md-6">
                  <label className="mb-2 text-gray">Coin : {dialogData.coin}</label>
                  {/* <input
                    type="number"
                    className="form-control"
                    required=""
                    placeholder="20"
                    value={coin}
                    onChange={(e) => {
                      setCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          coin: "Coin is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          coin: "",
                        });
                      }
                    }}
                  />
                  {errors.coin && (
                    <div className="ml-2 mt-1">
                      {errors.coin && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.coin}</span>
                        </div>
                      )}
                    </div>
                  )} */}
                </div>
                <div className="form-group col-md-6">
                  <label className="text-gray mb-2">Category : {categories.find(item => item._id == dialogData.category)?.name}</label>
                  {/* {GiftClick === null ? (
                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="Category Name"
                      disabled
                      value={categoryDetail.name}
                    />
                  ) : (
                    <>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        value={category}
                        disabled
                        onChange={(e) => {
                          setCategory(e.target.value);
                          if (e.target.value === "Select Category") {
                            return setError({
                              ...errors,
                              category: "Please select a Category!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              category: "",
                            });
                          }
                        }}
                      >
                        <option value="Select Category" selected>
                          Select Category
                        </option>
                        {categories.map((category) => {
                          return (
                            <option key={category._id} value={category._id}>
                              {category.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.category && (
                        <div className="ml-2 mt-1">
                          {errors.category && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.category}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )} */}
                </div>
                <div className="form-group col-md-6">
                  <label className="mb-2 text-gray">Select Image, GIF, Lottie Json File</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept="image/*, .json"
                    required=""
                    onChange={(e) => handleInputImage(e, "image")}
                  />
                  {errors.image && (
                    <div className="ml-2 mt-1">
                      {errors.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.image}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {imageData?.type === "application/json" || (imageData?.preview ?? imagePath)?.toLowerCase().endsWith([".json"]) || (imageData?.preview ?? imagePath)?.toLowerCase().endsWith([".lottie"]) ?
                    <dotlottie-player src={imageData?.preview ?? imagePath} autoplay loop style={{
                      width: 100,
                      height: 100,
                      boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                      borderRadius: 10,
                      marginTop: 10,
                      float: "left",
                      objectFit: "contain",
                    }} />
                    :
                    <img
                      height="120px"
                      width="120px"
                      alt="app"
                      src={imageData?.preview ?? imagePath}
                      style={{
                        boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                        borderRadius: 10,
                        marginTop: 10,
                        float: "left",
                        objectFit: "contain",
                      }}
                    />
                  }
                </div>
                <br />
                {(imageData?.type === "application/json" || (imageData?.preview ?? imagePath)?.toLowerCase().endsWith([".json"])
                  || (imageData?.preview ?? imagePath)?.toLowerCase().endsWith([".lottie"]) || dialogData?.type === "image/gif"
                ) && <div className="form-group  col-md-6">
                    <label className="mb-2 text-gray">Select Preview Image</label>
                    <input
                      type="file"
                      className="form-control form-control-sm"
                      accept="image/png, image/jpeg, image/jpg"
                      required=""
                      onChange={(e) => handleInputImage(e, "preview")}
                    />
                    {errors.preview && (
                      <div className="ml-2 mt-1">
                        {errors.preview && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.preview}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {(previewImg?.preview || previewPath) &&
                      <img
                        height="120px"
                        width="120px"
                        alt="app"
                        src={previewImg?.preview ?? previewPath}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                          borderRadius: 10,
                          marginTop: 10,
                          float: "left",
                          objectFit: "contain",
                        }}
                      />}
                  </div>}
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { editGift, getCategory })(GiftDialog);
