import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
//routing
import { Link } from "react-router-dom";
//MUI
import { TablePagination, Tooltip } from "@material-ui/core";
// type
import { OPEN_AGENCY_DIALOG } from "../../store/agency/types";
//sweet alert
import { alert, warning, permissionError } from "../../util/Alert";
import arraySort from "array-sort";
import AgencyDialog from "../dialog/Agency";
import { getAgency, deleteAgency} from "../../store/agency/action";
import Pagination from "../../pages/Pagination";


const TablePaginationActions = React.lazy(() => import("./TablePagination"));
const AgencyTable = (props) => {
  const dispatch = useDispatch();

  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const [search, setSearch] = useState(window.location.search.split('search=')[1] || "ALL");
  const [cancelFn, setCancelFn] = useState(() => () => { });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {agency,totalUser} = useSelector((state) => state.agency);
  useEffect(() => {
    props.getAgency(page,rowsPerPage,search); // eslint-disable-next-line
  }, [page,rowsPerPage,search]);
  

  useEffect(() => {
    setData(agency);
  }, [agency]);

  const handlePageChange = (pageNumber) => { 
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    handlePageChange(1);
    setRowsPerPage(value);
  };
  const handleUpdate = (data) => {
    dispatch({ type: OPEN_AGENCY_DIALOG, payload: data });
  };


  const handleOpen = () => {
    dispatch({ type: OPEN_AGENCY_DIALOG });
  };
  const handleSearchInput = (event) => {
    event.preventDefault()
    let canceled = false;
    let timer;
    const newCancelFn = () => {
        canceled = true;
        clearTimeout(timer);
    };

    const query = event.target.value;

    if (query) {
        timer = setTimeout(() => {
          setSearch(query);
          setPage(1);
        }, 600);
    }else setSearch("");

    setCancelFn(() => newCancelFn);
  };
  const handleDelete = (agencyId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          if (!hasPermission) return permissionError();
          props.deleteAgency(agencyId);
          alert("Deleted!", `Agency has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

    return (
        <>
          <div className="page-title">
            <div className="row">
              <div className="col-12 col-md-6 order-md-1 order-last">
                <h3 className="mb-3">Agency</h3>
              </div>
              <div className="col-12 col-md-6 order-md-2 order-first">
                <nav
                  aria-label="breadcrumb"
                  className="breadcrumb-header float-start float-lg-end"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard" className="text-danger">
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Agency
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="bannerDialog"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        id="searchBar"
                        defaultValue={search!='ALL'?search:""}
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          handleSearchInput(e)
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Agency Name</th>
                    <th>Update Agency</th>
                    <th>Delete Agency</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                     data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.name}</td>
                          <td>
                            <Tooltip title="Edit">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => handleUpdate(data)}
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Delete">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(data._id)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
                 <Pagination
                activePage={parseInt(page)}
                rowsPerPage={parseInt(rowsPerPage)}
                userTotal={totalUser}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <AgencyDialog/>
        </>
      );


};
export default connect(null, {getAgency,deleteAgency})(AgencyTable);