import axios from "axios";
import { Toast } from "../../util/Toast";
import { formatDateTime } from "../../util/Helper";
import { GET_PK_SESSIONS } from "./types";
import dayjs from "dayjs";

// export async function getPKSessions (multiLiveRoomId, start,limit) {
//     // let url = `live-streaming-history/streamer-task-summary/${userID}?selectedDate=${date}`
//     let url = `pk-session?multiLiveRoomId=${multiLiveRoomId}&start=${start}&limit=${limit}&sortType=-1&sortBy=`
//     return new Promise((resolve, reject) => {
//       axios.get(url)
//           .then((response) => {
//               const { data } = response;
//               resolve(data);
//           })
//           .catch((error) => {
//               if (error.response) {
//                   if (error.response.data) {
//                       reject({ http_code: error.response.status, data: error.response.data });
//                   } else {
//                       reject(error.response.data);
//                   }
//               } else {
//                   reject(error?.message);
//               }
//           });
//   });
//   }

  export const getPKSessions =
  (multiLiveRoomId, start,limit) => (dispatch) => {
    let url = `pk-session?multiLiveRoomId=${multiLiveRoomId}&start=${start}&limit=${limit}&sortType=-1&sortBy=`

    axios.get(url)
      .then((res) => {
        if (res.status) {
        
          dispatch({
            type: GET_PK_SESSIONS,
            payload: res.data?.data,
            total:res.data?.total,
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };