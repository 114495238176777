import React, { useEffect, useState } from "react";

//jquery
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import { getAllAnnouncement, deleteAnnouncement } from "../../store/announcement/action";

//routing
import { Link, useHistory } from "react-router-dom";

//pagination
import { TablePagination, Tooltip } from "@material-ui/core";

// dayjs
import dayjs from "dayjs";

// base url
import { baseURL } from "../../util/Config";

//alert
import { warning, alert, permissionError } from "../../util/Alert";

//image
import noImage from "../../assets/images/noImage.png";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import { OPEN_ANNOUNCEMENT_DIALOG } from "../../store/announcement/types";

// dialog
import AnnouncementDialog from "../dialog/Announcement";

const AnnouncementTable = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const TablePaginationActions = React.lazy(() => import("./TablePagination"));

  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const { announcement } = useSelector((state) => state.announcement);

  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  useEffect(() => {
    window.addEventListener('click', function(e){   
      if (!document.getElementById('datePicker')?.contains(e.target)){
        $("#datePicker").removeClass("show");
      }
    });
  }, []);
  
  useEffect(() => {
    props.getAllAnnouncement(null, activePage, rowsPerPage, sDate, eDate); // eslint-disable-next-line
  }, [activePage, rowsPerPage, sDate, eDate]);

  useEffect(() => {
    setData(announcement);
  }, [announcement]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker").removeClass("show");
    setData(announcement);
  }, [date, announcement]);

  const handleOpen = () => {
    dispatch({ type: OPEN_ANNOUNCEMENT_DIALOG });
  };

  const handleDelete = (announcementId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          if (!hasPermission) return permissionError();
          props.deleteAnnouncement(announcementId);
          alert("Deleted!", `Announcement has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_ANNOUNCEMENT_DIALOG, payload: data });
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Announcement</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Announcement
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left my-3">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      type="button"
                      className="btn waves-effect waves-light btn-danger btn-sm float-left"
                      onClick={handleOpen}
                      id="announcementDialog"
                    >
                      <i className="fa fa-plus"></i>
                      <span className="icon_margin">New</span>
                    </button>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right"></div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("DD/MM/YYYY");
                          const dayEnd = dayjs(item.selection.endDate).format(
                            "DD/MM/YYYY"
                          );
                          setActivePage(1);
                          setsDate(dayStart);
                          seteDate(dayEnd);
                          props.getAllAnnouncement(
                            null,
                            activePage,
                            rowsPerPage,
                            sDate,
                            eDate
                          );
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Message</th>
                    <th>Type</th>
                    {/* <th>is VIP</th> */}
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Created At</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data.image ? baseURL + data.image : noImage}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                float: "left",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{data.title}</td>
                          <td><span className="multiline-ellipsis">{data.message}</span></td>
                          <td>{data.type}</td>
                          {/* <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data.isVIP}
                                onChange={() => handleVIPSwitch_(data._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data.isVIP ? "5px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data.isVIP ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td> */}
                          <td>{moment(data.startDateTime).format("DD/MM/YYYY")}</td>
                          <td>{moment(data.endDateTime).format("DD/MM/YYYY")}</td>
                          <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                          <td>
                            <Tooltip title="Edit">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleEdit(data)}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Delete">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(data._id)}
                              >
                                <i className="fas fa-trash-alt fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <TablePagination
                id="pagination"
                component="div"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  100,
                  { label: "All", value: -1 },
                ]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                classes="menuItem"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </div>
        </div>
      </div>
      <AnnouncementDialog/>
    </>
  );
};

export default connect(null, { getAllAnnouncement, deleteAnnouncement })(AnnouncementTable);
