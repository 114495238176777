import { GET_TOP_RECEIVER_TOTAL_RECEIVED, GET_TOP_SENDER_TOTAL_SENT } from "./types"

const initialState = {
  rankUser: [],
  total: 0,
};

const rankingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOP_RECEIVER_TOTAL_RECEIVED:
      return {
        ...state,
        rankUser: action.payload,
        total: action.total,
      };
    case GET_TOP_SENDER_TOTAL_SENT:
      return {
        ...state,
        rankUser: action.payload,
        total: action.total,
      };
      default:
        return state;
  }
};

export default rankingListReducer;
