import React, { useState } from "react";

//redux
import { useSelector } from "react-redux";

//MUI
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import { permissionError } from "../../util/Alert";

const SolveComplain = (props) => {
    const { closePopup, submitData } = props;
    const hasPermission = useSelector((state) => state.admin.admin.flag);

    const [remarks, setRemarks] = useState("");
    const [errors, setError] = useState({
        remarks: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!hasPermission) return permissionError();
        submitData({ remarks });
    };

    return (
        <>
            <Dialog
                open={true}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableEscapeKeyDown
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4"> Solve Complain </span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div className="modal-body pt-1 px-1 pb-3">
                        <div className="d-flex flex-column">
                            <form>
                                <div className="form-group">
                                    <label className="mb-2 text-gray">Remarks</label>

                                    <textarea
                                        type="text"
                                        rows={3}
                                        cols={3}
                                        className="form-control"
                                        required=""
                                        placeholder="Enter Remarks"
                                        value={remarks}
                                        onChange={(e) => {
                                            setRemarks(e.target.value);
                                        }}
                                    />

                                    {errors.remarks && (
                                        <div className="ml-2 mt-1">
                                            {errors.remarks && (
                                                <div className="pl-1 text__left">
                                                    <span className="text-red">{errors.remarks}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="mt-5">
                                    <button
                                        type="button"
                                        className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                                        onClick={closePopup}
                                    >
                                        Close
                                    </button>
                                    <button type="button" className="btn btn-round float__right btn-danger" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SolveComplain;
